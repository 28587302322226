import React, { Component } from "react";
import "../styles/main.scss";

import Footer from "../components/footer";

export default class Home extends Component {
    render() {
        return(
            <div>
                <div className="nest">
                    <div className="section">
                        <div className="image">
                            <div className="tint" />
                            
                            <img src="/assets/company.jpg" alt="Alaska Teknoloji Kurumsal" />
                        </div>
                    </div>

                    <div className="section">
                        <div className="title">Kurumsal</div>
                        <div className="line" />

                        <div className="single">
                            <div className="title">Biz Kimiz?</div>
                            <div className="line" />
                            
                            <div className="text">
                                Alaska Teknoloji sektörde geçen 10 yılı aşkın bilgi ve tecrübeyi sizlerle paylaşmak ve
                                firmanıza değer katan projeler geliştirmek amacıyla kurulmuştur.
                                Her koşulda müşteri memnuniyeti temel ilkemizdir. Odak noktamız son teknolojiler ile donatılmış
                                ve kalite bilinci yüksek çözüm ve hizmetler sunmaktır.
                                Müşterilerine katma değerli çözümler sunmak, BT sektörün gelişimine katkıda bulunmak,
                                teknoloji geliştiren personel istihdamı sağlamak ve bununla beraber çalışanlarımıza kendilerini geliştirme imkanını sunmak
                                birincil hedeflerimizdir.
                            </div>
                        </div>

                        <div className="single gap">
                            <div className="title">Neden Biz?</div>
                            <div className="line" />

                            <ul>
                                <li>İş süreçlerinizi digital platforma taşırken %100 sonuç odaklı yaklaşım.</li>
                                <li>Tüm projelerde kullanıcı deneyimini ön planda tutmak, sade işlevsel yapı.</li>
                                <li>İş ahlakına bağlı ve yüksek sorumluluk duygusu ile sürdürülebilir ilişkiler.</li>
                                <li>Yazılım geliştirme süreçlerinde şeffaflık.</li>
                                <li>Hızlı, esnek ve ekonomik çözüm üretme.</li>
                                <li>Satış sonrası kusursuz eğitim ve destek hizmeti.</li>
                                <li>Değişime ve gelişime açık yönetim anlayışı.</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        )
    }
}